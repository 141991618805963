<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-bind="dataTableAttrs"
    :headers="headers"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
  >
    <!-- eslint-disable-next-line -->
    <template v-slot:top>
      <CollectingDetail ref="collectingDetailForm" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <a
        class="btn btn-icon btn-sm btn-clean"
        href="#"
        @click.prevent="showCollectingDetail(item)"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.collected_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import hasDataTable from "@/view/mixins/hasDataTable";
import CollectingDetail from "@/view/pages/income/forms/CollectingDetail";

export default {
  name: "ProjectIncomeList",
  mixins: [hasDataTable],
  components: {
    CollectingDetail,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    projectId: {
      handler() {
        this.loadList();
      },
      immediate: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: this.$t("labels.date"),
          value: "collected_on",
          searchable: "date",
        },
        {
          text: "Makbuz No",
          value: "collecting_number",
          searchable: "text",
        },
        {
          text: "Sakin",
          value: "resident",
          searchable: "text",
        },
        {
          text: this.$t("labels.amount"),
          value: "amount",
          searchable: "number",
          align: "end",
        },
      ],
    };
  },
  methods: {
    loadList() {
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      if (this.isLoading || !this.projectId) {
        return;
      }

      this.isLoading = true;

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.project = this.projectId;
      // params.list_type = this.listType;

      this.$api
        .query("income/collectings", {
          params,
        })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;

            this.$emit("update:total", this.footTotals.amount);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    showCollectingDetail(item) {
      this.$refs.collectingDetailForm.show(item.id);
    },
  },
};
</script>
