<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      Tahsilat<br />
      <small>{{ originalData.collecting_number }}</small>
    </template>
    <template slot="body" v-if="originalData.house">
      <v-row dense cols="12">
        <v-col sm="3"><strong>TYA</strong></v-col>
        <v-col sm="3">
          <router-link
            :to="{
              name: 'definitions.clusters.show',
              params: { id: originalData.house.block.cluster_id },
            }"
          >
            {{ originalData.house.block.cluster.name }}
          </router-link>
        </v-col>
        <v-col sm="3"><strong>BB No</strong></v-col>
        <v-col sm="3">
          <router-link
            v-if="can('see-house-income')"
            :to="{
              name: 'incomes.houses.show',
              params: { id: originalData.house_id },
            }"
          >
            {{ originalData.house.door_number }}
          </router-link>
          <span v-else>
            {{ originalData.house.door_number }}
          </span>
        </v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Tahsilat Türü</strong></v-col>
        <v-col sm="3">
          {{ originalData.collecting_type.name }}
        </v-col>
        <v-col sm="3"><strong>Sistem No</strong></v-col>
        <v-col sm="3">{{ originalData.collecting_number }}</v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Tahsilat Tarihi</strong></v-col>
        <v-col sm="3">
          {{ new Date(originalData.collected_on).toLocaleDateString() }}
        </v-col>
        <v-col sm="3">
          <strong v-if="originalData.bank_account_id">Banka Hesabı</strong>
          <strong v-else-if="originalData.safe_id">Kasa</strong>
        </v-col>
        <v-col sm="3">
          <strong v-if="originalData.bank_account_id">
            <router-link
              :to="{
                name: 'definitions.bank-accounts.show',
                params: { id: originalData.bank_account_id },
              }"
            >
              {{ originalData.bank_account.name }}
            </router-link>
          </strong>
          <strong v-else-if="originalData.safe_id">
            <router-link
              :to="{
                name: 'definitions.safes.show',
                params: { id: originalData.safe_id },
              }"
            >
              {{ originalData.safe.name }}
            </router-link>
          </strong>
        </v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Ödeyen</strong></v-col>
        <v-col sm="3">
          <router-link
            v-if="can('see-house-income')"
            :to="{
              name: 'incomes.residents.show',
              params: { id: originalData.resident_id },
            }"
          >
            {{ originalData.resident.name }}
          </router-link>
          <span v-else>
            {{ originalData.resident.name }}
          </span>
        </v-col>
        <v-col sm="3"><strong>Tutar</strong></v-col>
        <v-col sm="3">
          <rs-table-cell-number price :value="originalData.amount" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <strong>Açıklama</strong>
          <p>
            {{ originalData.description }}
          </p>
        </v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col cols="12">
          <h3>Kapanan Borçlar</h3>
        </v-col>

        <v-col cols="12">
          <v-btn
            v-if="
              originalData.debt_collectings.length &&
              !isActivity &&
              can('edit-house-income')
            "
            class="float-end"
            color="pink"
            outlined
            small
            @click="handleDeleteClick"
            :disabled="selectedItems.length === 0"
            :loading="isLoading"
          >
            Borç Kapamayı İptal Et
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="originalData.debt_collectings.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th>Tahakkuk Tarihi</th>
                  <th>Borçlu Kişi</th>
                  <th>Kapanan Tutar</th>
                  <th>Kapanan Ana Para</th>
                  <th>Kapanan Gecikme</th>
                  <th>Son Ödeme Tarihi</th>
                  <th>Fark Gün</th>
                  <th>Açıklama</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in originalData.debt_collectings"
                  :key="item.id"
                >
                  <td>
                    <v-checkbox
                      v-model="selectedItems"
                      label=""
                      :value="item"
                    />
                  </td>
                  <td>
                    <rs-table-cell-date
                      :value="item.debt.house_assessment.issues_on"
                    />
                  </td>
                  <td>
                    {{ item.debt.resident.name }}
                  </td>
                  <td>
                    <rs-table-cell-number
                      price
                      :value="item.amount + item.deferment_amount"
                    />
                  </td>
                  <td>
                    <rs-table-cell-number price :value="item.amount" />
                  </td>
                  <td>
                    <rs-table-cell-number
                      price
                      :value="item.deferment_amount"
                    />
                  </td>
                  <td>
                    <rs-table-cell-date
                      :value="item.debt.house_assessment.last_payment_on"
                    />
                  </td>
                  <td>
                    {{ getDayDiff(item) }}
                  </td>
                  <td>{{ item.debt.house_assessment.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" v-else>
          <p>Bu tahsilatla hiç borç kapatılmadı.</p>
        </v-col>
      </v-row>

      <rs-form-buttons
        :is-loading="isLoading"
        @cancel="hide()"
        hide-submit
        hide-save-close
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteSubmitClick"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "CollectingDetail",
  mixins: [hasForm, hasPermissions],
  props: {
    id: {
      type: Number,
      required: false,
    },
    isActivities: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      idReal: null,
      formData: {},
      selectedItems: [],
      isActivity: false,
    };
  },
  methods: {
    show(id, isActivities) {
      this.formData = {
        deferment_interest_percent: null,
        deferment_grace_period: null,
        deferment_date: new Date().toJSON().split("T")[0],
        stop_deferment_on: null,
      };
      this.originalData = {};
      this.stopDeferment = false;

      this.$refs.sidePanel.show();

      if (this.id) {
        this.idReal = this.id;
      } else {
        this.idReal = id;
      }

      if (this.isActivities) {
        this.isActivity = this.isActivities;
      } else {
        this.isActivity = isActivities;
      }

      this.load();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading || !this.idReal) return false;

      this.isLoading = true;
      this.selectedItems = [];

      this.$api
        .query(`income/collectings/${this.idReal}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (this.formData.deferment_date) {
            this.formData.deferment_date =
              this.formData.deferment_date.split("T")[0];
          }

          // if (this.formData.stop_deferment_on) {
          //   this.stopDeferment = true;
          // }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteClick() {
      if (this.selectedItems.length === 0) {
        return;
      }

      this.$refs.confirmDelete.show(
        "Borç kapamayı iptal etmek istediğinizden emin misiniz?"
      );
    },
    handleDeleteSubmitClick() {
      this.$refs.confirmDelete.hide();

      const params = {
        debt_collecting_ids: this.selectedItems.map((item) => item.id),
      };

      this.isLoading = true;

      this.$api
        .post("income/cancel-debt-collectings", params)
        .then(() => {
          setTimeout(() => {
            this.load();
            this.$emit("saved");
          }, 100);
          this.$toast.success("Seçilen borç kapamalar iptal edildi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getDayDiff(item) {
      const dt1 = new Date(item.debt.house_assessment.last_payment_on);
      const dt2 = new Date(this.originalData.collected_on);

      const diff = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );

      return diff > 0 ? diff : 0;
    },
  },
};
</script>
